<template>
  <v-card-text>
    <h2>
      <a v-if="isSharepoint" :href="item.link" v-text="calTitle"></a>
      <span v-else v-text="calTitle"></span>
    </h2>
    <div v-if="this.jpgs.length === 1">
      <v-img :src="jpgs[0]" :width="width"></v-img>
      <br />
      <a v-if="isSharepoint" :href="item.link" v-text="calTitle"></a>
    </div>
    <b-slide v-if="this.jpgs.length > 1" :jpgs="jpgs"></b-slide>
    <br />
    <a-photos v-if="this.specs.length !== 0" :jpgs="specs" :width="width"
      >スペックボード</a-photos
    >
    <a-photos v-if="this.features.length !== 0" :jpgs="features" :width="width"
      >フィーチャー写真</a-photos
    >
    <spec-table :item="item"></spec-table>
    <div v-for="(tip, m) in tipList" :key="`tip-${m}`">
      <v-alert border="left" colored-border :color="tip.color" elevation="1">{{
        item[tip.id]
      }}</v-alert>
    </div>
    <v-btn
      v-for="(pp, i) in pptxs"
      :key="i"
      :href="pp.path"
      :target="pp.name"
      color="success"
    >
      <v-icon left>mdi-download</v-icon>
      {{ pp.name }}
    </v-btn>
  </v-card-text>
</template>
<script>
import config from "@/js/const";
import BSlide from "@/components/Bases/Slide";
import APhotos from "@/components/ModelClass/AppPhotos";
import SpecTable from "@/components/ModelClass/SpecTable.vue";

export default {
  components: {
    BSlide,
    APhotos,
    SpecTable,
  },
  data: () => ({}),
  props: {
    width: {
      type: Number,
      default: 400,
    },
    item: {
      type: Object,
    },
    features: {
      type: Array,
    },
    specs: {
      type: Array,
    },
    jpgs: {
      type: Array,
    },
    pptxs: {
      type: Array,
    },
  },
  computed: {
    tipList() {
      return config.fields.filter((e) => e.type === "tip" && this.item[e.id]);
    },
    calTitle() {
      const title =
        this.item.modelyear +
        "_" +
        this.item.modelname +
        "_" +
        this.item.destination;
      document.title = title;
      return title;
    },
    isSharepoint() {
      if (this.item.id) {
        return parseInt(this.item.id, 10) < 18659;
      }
      return false;
    },
  },
};
</script>
