<template>
  <div class="mytable mb-5">
    <table>
      <tr v-for="(tr, n) in trList" :key="n">
        <th v-text="tr.label"></th>
        <td v-text="item[tr.id]"></td>
      </tr>
    </table>
  </div>
</template>
<script>
import config from "@/js/const";

export default {
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    trList() {
      return config.fields.filter((e) => e.type === "tr");
    },
  },
};
</script>
<style lang="scss" >
.mytable table {
  border-collapse: separate;
  border-spacing: 1px;
  background: #333333;
  max-width: 720px;
}
.mytable td {
  background-color: white;
  padding: 3px;
}
.mytable th {
  padding: 3px;
  color: white;
  background-color: gray;
}
</style>