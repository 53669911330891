<template>
  <v-container class="white">
    <v-card width="600" class="mx-auto mt-5">
      <model-view v-bind="$data"></model-view>
      <model-years
        :relationlinks="relationlinks"
        :id="item.id"
        @link-click="updateQuery($event)"
        v-if="relationlinks.length > 1"
      >
      </model-years>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import config, { calcPath } from "@/js/const";
import { taffy } from "taffydb";
import csv from "csvtojson";
import ModelYears from "@/components/ModelClass/ModelYears.vue";
import ModelView from "@/components/ModelClass/ModelView.vue";
const MYFILES = [
  { id: "jpgs", ext: ["jpg", "png"], type: "picture" },
  { id: "features", ext: ["feature"], type: "picture" },
  { id: "specs", ext: ["spec"], type: "picture" },
  { id: "pptxs", notExt: ["dir"], type: "others" },
];

export default {
  name: "MyModel",
  components: {
    ModelView,
    ModelYears,
  },
  data: () => ({
    item: {},
    relationlinks: [],
    features: [],
    specs: [],
    jpgs: [],
    pptxs: [],
  }),
  mounted() {
    this.loadData();
    this.updateQuery();
  },
  methods: {
    loadData() {
      this.relationlinks.push(
        ...JSON.parse(
          window.localStorage.getItem("relation_" + this.$route.query.r_id)
        )
      );
    },
    updateQuery(id) {
      if (!id) {
        id = this.$route.query.id;
      }
      this.item = this.relationlinks.find((r) => r.id === id);
      this.getData();
    },
    async getData() {
      //ファイルの初期化
      MYFILES.forEach((file) =>
        this.$data[file.id].splice(0, this.$data[file.id].length)
      );
      if (parseInt(this.item.id, 10) < 16471) {
        await this.get2018();
        return;
      }
      if (this.isSharepoint) {
        await this.get2019();
        return;
      }
      if (parseInt(this.item.id, 10) < 19904) {
        await this.get2020("2020");
        return;
      }
      if (parseInt(this.item.id, 10) < 21672) {
        await this.get2020("2021");
        return;
      }
      if (parseInt(this.item.id, 10) < 23925) {
        await this.get2020("2022");
        return;
      }
      if (parseInt(this.item.id, 10) < 25572) {
        await this.get2020("2023");
        return;
      }
      if (parseInt(this.item.id, 10) < 27969) {
        await this.get2020("2024");
        return;
      }
      await this.get2020("2025");
    },
    async get2019() {
      this.jpgs.push(
        config.protocol_name + "/data/2019/" + this.item.id + ".jpg"
      );
      await this.getFiles(
        "/2019" +
          this.item.link.substring(this.item.link.lastIndexOf("/")) +
          "/"
      );
    },
    async get2020(year) {
      let url =
        "/" + year + "/" + this.item.maker + "/" + this.item.destination + "/";
      if (this.item.link) {
        url += this.item.link;
      } else {
        url += this.item.modelname;
      }
      await this.getFiles(url + "/");
    },
    async get2018() {
      const data = await axios.get("./data/directory.txt").then();
      csv({ delimiter: "\t" })
        .fromString(data.data)
        .then((json) => {
          const mydb = taffy(JSON.stringify(json));
          const result = [];
          mydb({ id: this.item.id }).each((r) => {
            result.push(r);
          });
          if (result.length > 0) {
            this.getFiles("/" + result[0].path.replace(/\\/g, "/") + "/");
          }
        });
    },
    pushDatas(id, path, files) {
      const addData = (file) => {
        if (id === "pptxs") {
          this.$data[id].push({
            path: calcPath(config.protocol_name, path, file),
            name: file,
          });
        } else {
          this.$data[id].push(calcPath(config.protocol_name, path, file));
        }
      };
      if (Array.isArray(files)) {
        files.map((file) => {
          addData(file);
        });
        return;
      }
      addData(files);
    },
    async getFiles(path) {
      const data = await axios
        .get(config.protocol_name + "/data/search.php?dir=" + encodeURI(path))
        .then();
      const others = MYFILES.find((f) => f.type === "others");
      Object.keys(data.data).forEach((file) => {
        const type_picture = MYFILES.filter(
          (f) => f.type === "picture"
        ).find((f) => f.ext.includes(file));
        if (type_picture) {
          this.pushDatas(type_picture.id, path, data.data[file]);
          return;
        }
        if (others.notExt.includes(file)) {
          return;
        }
        this.pushDatas(others.id, path, data.data[file]);
        return;
      });
    },
  },
};
</script>
