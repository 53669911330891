<template>
  <div>
    <v-row class="justify-end">
      <v-switch v-model="switches.cycle" :label="`自動スライド: ${switches.cycle.toString()}`"></v-switch>
    </v-row>
    <v-carousel v-if="this.jpgs.length>0" v-model="slides" :cycle="switches.cycle" hide-delimiters>
      <v-carousel-item v-for="(jp, i) in jpgs" :key="i" @click="clickCitem(jp)">
        <v-img :src="encodeURI(jp)" :width="568"></v-img>
      </v-carousel-item>
    </v-carousel>
    <br />
    <v-row class="justify-end">
      <v-switch v-model="switches.thumbnail" :label="`サムネイル表示: ${switches.thumbnail.toString()}`"></v-switch>
    </v-row>
    <br />
    <v-row v-show="switches.thumbnail">
      <v-col
        v-for="(jp, i) in jpgs"
        :key="i"
        :src="encodeURI(jp)"
        class="d-flex child-flex"
        cols="4"
      >
        <v-card flat tile class="d-flex">
          <v-img :src="jp" aspect-ratio="1" :class="calcBorder(i)" @click="slides=i;">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    slides: 0,
    switches: {
      cycle: false,
      thumbnail: true,
    },
  }),
  props: {
    jpgs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    calcBorder(val) {
      return val === this.slides
        ? { "border-red": true }
        : { "border-gray": true };
    },
    clickCitem(item) {
      window.open(item, "_blank");
    },
  },
};
</script>
<style lang="scss" >
.border-red {
  border: 2px solid red;
}
.border-gray {
  border: 1px solid gray;
}
</style>