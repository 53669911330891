<template>
  <div class="mt-4 mb-4">
    <v-divider class="mb-4"></v-divider>
    <span class="title mb-2">
      <slot></slot>
    </span>
    <div v-if="jpgs.length === 1">
      <v-img :src="jpgs[0]" :width="width"></v-img>
    </div>
    <b-slide v-if="jpgs.length > 1" :jpgs="jpgs"></b-slide>
    <br />
  </div>
</template>
<script>
import BSlide from "@/components/Bases/Slide";
export default {
  components: {
    BSlide,
  },
  props: {
    jpgs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    width: {},
  },
};
</script>
