<template>
  <v-card-text>
    <span class="title mb-2">モデルイヤー違いリンク集</span>
    <div v-for="(pp, i) in relationlinks" :key="i">
      <span v-if="pp.id !== id">
        <v-btn color="info" class="mb-6" @click="clickLink(pp)">
          <v-icon left>mdi-link</v-icon>
          {{ pp.modelyear }}_{{ pp.modelname }}
        </v-btn>
        <v-btn
          color="info"
          class="ml-6 mb-6"
          fab
          small
          @click="clickLink(pp, true)"
        >
          <v-icon>mdi-link-plus</v-icon>
        </v-btn>
        <br />
      </span>
    </div>
  </v-card-text>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
    },
    relationlinks: {
      type: Array,
    },
  },
  methods: {
    clickLink(data, isPlus) {
      if (isPlus) {
        const resolvedRoute = this.$router.resolve({
          name: "ModelClass",
          query: { id: data.id, r_id: this.$route.query.r_id },
        });
        window.open(resolvedRoute.href);
        return;
      }
      this.$emit("link-click", data.id);
      window.scrollTo(0, 0);
    },
  },
};
</script>